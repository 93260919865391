<template>
  <div v-if="items.length" class="append append-images">
    <p class="append-title">相關圖片</p>
    <div class="row list-images">
      <div
        v-for="(image, index) in items"
        :key="index"
        class="col-md-2 h-100"
        @click="() => showImg(index)"
      >
        <img class="img-fluid img-thumbnail" :src="image.fileUrl" :alt="image.fileSource" />
      </div>
    </div>
  </div>
  <vue-easy-lightbox
    :visible="lightbox.visible"
    :imgs="imgs"
    :index="lightbox.index"
    @hide="handleHide"
  ></vue-easy-lightbox>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default() {
        return []
      }
    },
    imgs: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      lightbox: {
        visible: false,
        index: 0
      }
    }
  },
  methods: {
    showImg(index) {
      this.lightbox.index = index
      this.lightbox.visible = true
    },
    handleHide() {
      this.lightbox.visible = false
    }
  }
}
</script>

<style></style>
