<template>
  <h1 v-text="subject"></h1>
  <!-- eslint-disable vue/no-v-html -->
  <div class="chapter" v-html="content"></div>
</template>

<script>
export default {
  props: {
    subject: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    }
  }
}
</script>

<style></style>
