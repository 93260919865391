<template>
  <div v-if="items.length" class="append append-files">
    <p class="append-title">附件</p>
    <div class="list-group list-downloads">
      <div
        v-for="(item, index) in items"
        :key="index"
        class="list-group-item d-flex justify-content-between align-items-center"
      >
        <div class="ms-2 me-auto">
          <a
            :href="item.fileUrl"
            target="_blank"
            class="btn btn-warning btn-sm"
            :title="item.fileSource"
            >下載</a
          >
          {{ item.fileSource }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default() {
        return []
      }
    }
  }
}
</script>

<style></style>
